import firebase from 'firebase/app';
import 'firebase/auth';
import 'firebase/firestore';

const siteConfigs = [
  {
    // Product School
    apiKey: "AIzaSyBc_MoClveEY78WC6-7--hjH4ZAMFGx_dY",
    authDomain: "product-school-face2face.firebaseapp.com",
    projectId: "product-school-face2face",
    storageBucket: "product-school-face2face.appspot.com",
    messagingSenderId: "478631164350",
    appId: "1:478631164350:web:67d188102e1b58a6a1a1ef",
    pxProductKey: "AP-81DXTR2UDK6D-2",
    siteHosts: ["pschool.face2faceweb.com","product-school-face2face.web.app"]
  },{
    // Trial
    apiKey: "AIzaSyCHKiYuWRklghGjCR9ts_bWxDinYlUnm3Q",
    authDomain: "trial-face2face.firebaseapp.com",
    projectId: "trial-face2face",
    storageBucket: "trial-face2face.appspot.com",
    messagingSenderId: "103266171884",
    appId: "1:103266171884:web:ce84221959e4c9e3ea1ec9",
    pxProductKey: "AP-PXBTDUTAYZ4F-2",
    siteHosts: ["trial.face2faceweb.com","trial-face2face.web.app"]
  },{
    apiKey: "AIzaSyBHvOic8b33bGnW2nkl2MErMh-3R6YYS0o",
    authDomain: "fir-face2face.firebaseapp.com",
    projectId: "fir-face2face",
    storageBucket: "fir-face2face.appspot.com",
    messagingSenderId: "956089587123",
    appId: "1:956089587123:web:b5316e8e42d679412327c6",
    pxProductKey: "AP-XXXXXXXXX-2",
    siteHosts: ["demo.face2faceweb.com","fir-face2face.web.app"]
  }
]

function getConfigForHost(hostname) {
  let configForHost = null; 
    for (const siteConfig of siteConfigs) {
      if (siteConfig.siteHosts.indexOf(hostname) !== -1) {
        configForHost = siteConfig;
        break;
      }
    }
    if (!configForHost) {
      // Default to product school
      configForHost = siteConfigs[0];
      console.log(`No match found for ${hostname}, defaulting to ${configForHost.pxProductKey}`)
    }
    return configForHost; 
}

function loadGainsightPX(config) {
    if (!config || !config.pxProductKey) {
      console.log(`Skipping PX load, no product key specified for host '${document.location.host}'`);
      return;
    }
    const pxProductKey = config.pxProductKey; 
    const pxConfig = {};

    window.aptrinsic = window.aptrinsic || function () {
        (window.aptrinsic.q = window.aptrinsic.q || []).push(arguments)
    }
    window.aptrinsic.p = pxProductKey;
    window.aptrinsic.c = pxConfig;
    
    let r = document.createElement("script");
    r.async = !0;
    r.src = `https://web-sdk.aptrinsic.com/api/aptrinsic.js?a=${pxProductKey}`;
    let c = document.getElementsByTagName("script")[0];
    c.parentNode.insertBefore(r, c);

}

const config = getConfigForHost(document.location.host);
loadGainsightPX(config);
firebase.initializeApp(config);

export const db = firebase.firestore();
export const auth = firebase.auth();

export default firebase;