import { createGlobalStyle } from "styled-components";


let style = {};
style['body-color'] = '#232333';
style['body-bg-color'] = '#39394B';

style['header-bg-color'] = '#FC7D07'; //ORANGE
style['header-text-color'] = '#FFF';

style['sidebar-button-color'] = '#FC7D07';
style['sidebar-text-color'] = '#f7f6fa';

style['logo-text-color'] = '#FC7D07';

style['button-bg-color'] = '#FC7D07';
style['button-bg-color-hover'] = '#FF9020';
style['button-border-color'] = '#0e71eb';
style['button-border-color-hover'] = '#2681f2';






export default createGlobalStyle`
:root {
    --button-bg-color: ${style['button-bg-color']};
    --button-bg-color-hover: ${style['button-bg-color-hover']};
    --header-bg-color: ${style['header-bg-color']};
    --header-text-color: ${style['header-text-color']};
    --logo-text-color: ${style['logo-text-color']};
    --body-color: ${style['body-color']};
    --body-bg-color: ${style['body-bg-color']};
    --sidebar-button-color: ${style['sidebar-button-color']};
    --sidebar-text-color: ${style['sidebar-text-color']};
}
`
