import styled from "styled-components";
import React from "react";
import { Route, Link } from "react-router-dom";
import PreviousMeetings from "./Previous/PreviousMeetings";
import PrivateRoute from "../../../Routes/PrivateRoute";
import { Tabs, Tab } from "../../Elements/Tabs";
import UpcomingMeetings from "./Previous/UpcomingMeetings";
import JoinMeeting from "./JoinMeeting";
import MeetingTemplates from "./MeetingTemplates";
import PersonalMeeting from "./PersonalMeeting";

import { useEffect } from "react";
import { db, auth } from "../../../config/firebase";
import { useState } from "react";


const Content_ = ({className}) => {
    const [user, setUser] = useState(null);
    const [account, setAccount] = useState(null);
    useEffect(()=>{
        console.log(auth.currentUser.email);
        db.collection('users').doc(auth.currentUser.email).get()
            .then(snap => {
                const user = snap.data();
                console.log(user);
                setUser(user);
                if (!user || !user.accountId) {
                    return;
                }
                
                
                db.collection('accounts').doc(user.accountId).get()
                .then(snap=>{
                    const account = snap.data();
                    console.log('account:', account);
                    setAccount(account);

                    })
                    .catch(err => {

                        console.log('err getting account', err)
                    })
            })
            .catch(err=>{
                console.log('err gettin user', err)
            });
    },[]);

    if(!user || !account) {
        return null;
    }

    const IdentifyUser = {
        id: auth.currentUser.uid,
        email: auth.currentUser.email,
    }

    const isPendo = false;
    let IdentifyAccount = null;

    // PX
    if (isPendo) {
        IdentifyAccount = {
            id: account.id,
            accountId: account.id,
        }

        console.log("user Profile",IdentifyUser);
        console.log("account Profile",IdentifyAccount);




        window.pendo.initialize({
            visitor: IdentifyUser,
            account: IdentifyAccount
        });

    } else {
        IdentifyAccount = {
            accountId: account.id,
        }

        console.log("user Profile",IdentifyUser);
        console.log("account Profile",IdentifyAccount);
        
        // PX
        window.aptrinsic("identify",
        IdentifyUser,
        IdentifyAccount
        );
        
        const vNum = 1 + ( (Math.floor(Math.random() * 2) + 1) / 10);     // returns a random version number of either 1.1, 1.2 or 1.3
        // window.aptrinsic('set', 'globalContext', {"version":1.2});
        console.log("setting globalContext to version=",vNum);
        window.aptrinsic('set', 'globalContext', {"version":vNum});
        console.log("set globalContext to version=",vNum);
    }
    
    return (
        <div className={className}>


            <Tabs>
                <Tab id="meetings-join" to="/meeting/join">Join a Meeting</Tab>
                <Tab id="meetings-upcoming" to="/meeting" exact>Upcoming Meetings</Tab>
                <Tab id="meetings-previous" to="/meeting/previous">Previous Meetings</Tab>
                <Tab id="meetings-personal" to="/meeting/personal">Personal Meeting Room</Tab>
                <Tab id="meetings-templates" to="/meeting/template/list">Meeting Templates</Tab>
            </Tabs>

            
            <PrivateRoute path="/meeting" exact>
                <UpcomingMeetings/>
            </PrivateRoute>

            <Route path="/meeting/join" exact>
                <JoinMeeting/>
            </Route>


            <PrivateRoute path="/meeting/previous">
                <PreviousMeetings/>
            </PrivateRoute>

            <PrivateRoute path="/meeting/template/list">
                <MeetingTemplates/>
            </PrivateRoute>

            <PrivateRoute path="/meeting/personal">
                <PersonalMeeting/>
            </PrivateRoute>

            {/* <div className="right">
                <Link to="/meetings-more">
                    Learn More
                </Link>
            </div> */}
            
        </div>
    )
}

const Content = styled(Content_)`
    margin: 20px;
    p {
        margin: 0;
    }
    .right {
        text-align: right;
        padding: 10px 20px;
    }
    
`

export default Content