import styled from "styled-components";
import React, { useContext, useState, useEffect } from "react";
import { db, auth } from "../../config/firebase";
import { AuthContext } from "../../Auth/Auth";
import clsx from "clsx";
import { Link } from "react-router-dom";
import { SettingsContext } from "../../state/settings";
import useCancelableTimeout from "use-cancelable-timeout";


import avatarImg from '../../images/avatar.png'




const ProfileMenu_ = ({className, onHover, onLeave, onClose, profile}) => {
    const [settings, updateSettings] = useContext(SettingsContext)
    
    const signout = e => {
        updateSettings({ activePopup: null });
        auth.signOut();
        window.aptrinsic('reset');
    }


    // if(!profile) return null;
    
    return (
        <div className={className} onPointerEnter={onHover} onPointerLeave={onLeave}>
            <div className="UserType">LICENSED</div>
            <Link className="MenuItem MenuItemProfile" to="/profile" onClick={onClose}>
                <b>{profile && profile.fullName ? profile.fullName : 'PROFILE'}</b>
                <span>{profile && profile.email}</span>
            </Link>
            <div className="divider"/>
            <div className="MenuItem" onClick={signout}>SIGN OUT</div>

        </div>
    )
}

const ProfileMenu = styled(ProfileMenu_)`
    position: absolute;
    min-width: 220px;
    min-height: 20px;
    right: 0;
    top: 96px;
    background: #fff;
    border: 1px solid #d3d3d3;
    box-sizing: border-box;
    border-radius: 8px;
    padding-top: 8px;
    padding-bottom: 8px;
    box-shadow: 0 0 15px rgba(0,0,0,0.15);
    line-height: 24px;
    white-space: nowrap;
    p {
        margin: 0;
    }

    .UserType {
        position: absolute;
        right: 10px;
        top: 16px;
        text-transform: uppercase;
        font-weight: bold;
        background: linear-gradient(120deg,#2e8cff,#fa6e26);
        -webkit-background-clip: text;
        color: transparent;
    }
    .MenuItem {
        display: block;
        padding: 8px 16px!important;
        font-size: 14px;
        color: #232333;
        padding-right: 80px!important;
        &:hover {
            text-decoration: none;
            background-color: #2d8cff;
            color: #fff;
            cursor: pointer;
        }
        &.MenuItemProfile {
            span {
                display: block;
            }
            &:hover {
                background-color: #fff;
                color: #0e71eb;
            }
        }
    }
    .divider {
        border-bottom: 1px solid #ededf3;
        padding-top: 8px;
        margin-bottom: 7px;
    }
`;



const Avatar_ = ({className}) => {
    const user = useContext(AuthContext);

    const [profile, setProfile] = useState(null);
    useEffect(()=>{
        // console.log(user.email);
        if(!user || !user.email) { return; }
        db.collection('users').doc(user.email).get()
            .then(snap => {
                console.log(snap.data());
                setProfile(snap.data());
            })
            .catch(err=>{
                console.log('err', err)
            });
    },[user]);

    
    const [settings, updateSettings] = useContext(SettingsContext)
    // const [menuOpen, setMenuOpen] = useState(false);

    const [onPointerLeave, cancelLeave] = useCancelableTimeout(()=>{
        updateSettings({ activePopup: null });
    }, 1000);

    useEffect(()=>{cancelLeave()},[settings.activePopup]);
    
    const onClose = e => {
        updateSettings({ activePopup: null });
    }
    const onHover = e => {
        cancelLeave();
        updateSettings({ activePopup: 'avatarMenu' })        
    }
    const onAvatarClick = e=>{
        settings.activePopup === 'avatarMenu' ? onClose() : onHover();
    }


    
    if(!user) return null;
    
    return (
        <div className={className}>
            <div className={clsx("Avatar", {
                signedin: !!user,
                signedout: !user && user!==0,
            })} onClick={onAvatarClick} style={{fontWeight:'bold' }}>
                <img src={(profile&&profile.image) || avatarImg} />
            </div>
            { settings.activePopup==='avatarMenu' && <ProfileMenu onHover={onHover} onLeave={onPointerLeave} onClose={onClose} profile={profile} /> }
            
        </div>
    )
}
const Avatar = styled(Avatar_)`
    width: 48px;
    padding: 5px 16px 5px 0;
    .Avatar {
        width: 32px;
        height: 32px;
        background-color: #ccc;
        border-radius: 8px;
        position: relative;
        border: 1px solid transparent;
        cursor: pointer;
        overflow: hidden;
        img {
            max-width: 100%;
        }

        &:hover {
            border-color: #0e71eb;
            outline: 0;
        }
    }
`

export default Avatar;