import styled from "styled-components";
import React, { useContext } from "react";
import Input from "../../ui/Elements/Input";
import H1 from "../../ui/Elements/H1";
import Button from "../../ui/Elements/Button";
import { Link, useRouteMatch, Redirect } from "react-router-dom";
import { auth, db } from "../../config/firebase";
import { useForm } from "react-hook-form";
import { AuthContext } from "../Auth";
import { useState } from "react";
import Alert from "../../ui/Elements/Alert";

// import users from '../../_usersAndAccounts/userList.json';
// import accounts from '../../_usersAndAccounts/accountList.json';



const createUserProfile = (email) => {
  const randMeetingId = Math.floor(1000000000 + Math.random() * 9000000000);
  const accountId = (Math.floor(10000000 + Math.random() * 90000000)).toString();
        
  const sendUserData = {
      personalMeetingId: randMeetingId,
      accountId
  }

  const sendAccountData = {
      id:accountId,

  }


  console.log('about to create user with sendUserData', email, sendUserData);

  db.collection('users').doc(email).set(sendUserData)
      .then(snap => {
          console.log('User Profile created');

          db.collection('accounts').doc(accountId).set(sendAccountData, {merge: true})
          .then(snap => {
                console.log('Account Profile created');

                const IdentifyUser = {
                    id: auth.currentUser.uid,
                    email: auth.currentUser.email,
                }

                const isPendo = false;
                let IdentifyAccount = null;

                // PENDO
                if (isPendo) {
                    IdentifyAccount = {
                        id: accountId,
                        accountId,
                    }

                    console.log("user Profile",IdentifyUser);
                    console.log("account Profile",IdentifyAccount);
    
                    window.pendo.initialize({
                        visitor: IdentifyUser,
                        account: IdentifyAccount
                    });

                } else {
                    IdentifyAccount = {
                        accountId,
                    }

                    console.log("user Profile",IdentifyUser);
                    console.log("account Profile",IdentifyAccount);
    
                    // PX
                    window.aptrinsic("identify",
                        IdentifyUser,
                        IdentifyAccount
                    );

                    const vNum = 1 + ( (Math.floor(Math.random() * 2) + 1) / 10);     // returns a random version number of either 1.1, 1.2 or 1.3
                    console.log("setting globalContext to version=",vNum);
                    window.aptrinsic('set', 'globalContext', {"version":vNum});
                    console.log("set globalContext to version=",vNum);
                }

     
          }).catch( err => {
              console.log('Error creating account profile', err)
          })
 

      }).catch( err => {
          console.log('Error creating user profile', err)
      });
    
    //console.log("User creation completed")
}


// const signout = () => auth.signOut();

const AuthContent_ = ({className}) => {
    let {path} = useRouteMatch(["/signin","/signup"]);
    const user = useContext(AuthContext);
    const [userProfile, setUserProfile] = useState(null);
    const [accountProfile, setAccountProfile] = useState(null);
    const { register, handleSubmit, watch, errors } = useForm();
    const [ alertMessage, setAlertMessage ] = useState();


    const signin = (email, password) => {

        auth.signInWithEmailAndPassword(email, password).then( function(result) {
        
            db.collection('users').doc(auth.currentUser.email).get()
            .then(snap => {
                // console.log("User Profile DB",snap.data());
                const userProfileData = snap.data();
                setUserProfile(userProfileData);

                db.collection('accounts').doc(userProfileData.accountId).get()
                    .then(snap => {
                        const accountProfileData = snap.data();
                        setAccountProfile(accountProfileData);

                        const IdentifyUser = {
                            id: auth.currentUser.uid,
                            email: auth.currentUser.email,
                            ...userProfileData
                            // firstName: userProfileData.firstName ? userProfileData.firstName : null,
                            // lastName: userProfileData.lastName ? userProfileData.lastName : null,
                        }
        
                        const isPendo = false;
                        let IdentifyAccount = null;
        
                        console.log("isPendo",isPendo);
                        // PENDO
                        if (isPendo) {
                            console.log("IS PENDO INIT")


                            IdentifyAccount = {
                                id: accountProfileData.id,
                                accountId: accountProfileData.id,
                                ...accountProfileData

                            }
        
                            console.log("user Profile",IdentifyUser);
                            console.log("account Profile",IdentifyAccount);
            
                            console.log("MAKE IDENTIFY CALL - PENDO")
                            window.pendo.initialize({
                                visitor: IdentifyUser,
                                account: IdentifyAccount
                            });

                             console.log("MADE IDENTIFY CALL")
        
                        } else {

                            console.log("IS PX INIT")

                            IdentifyAccount = {
                                accountId: accountProfileData.id,
                                ...accountProfileData
                                // name: accountProfileData.name ? accountProfileData.name : null,
                            }
        
                            console.log("user Profile",IdentifyUser);
                            console.log("account Profile",IdentifyAccount);
            
                            // PX
                            console.log("MAKE IDENTIFY CALL - PX")
                            window.aptrinsic("identify",
                                IdentifyUser,
                                IdentifyAccount
                            );

                             
                        }

                        // const IdentifyUser = {
                        //     id: auth.currentUser.uid,
                        //     email: auth.currentUser.email,
                        //     ...userProfileData
                        //     // firstName: userProfileData.firstName ? userProfileData.firstName : null,
                        //     // lastName: userProfileData.lastName ? userProfileData.lastName : null,
                        // }

                        // const IdentifyAccount = {
                        //     accountId: accountProfileData.id,
                        //     ...accountProfileData
                        //     // name: accountProfileData.name ? accountProfileData.name : null,
                        // }
        
                        // console.log("user Profile",IdentifyUser);
                        // console.log("account Profile",IdentifyAccount);
        
                        // console.log("MAKING IDENTIFY CALL")
                        // window.aptrinsic("identify",
                        // IdentifyUser,
                        // IdentifyAccount
                        // );
                        // console.log("MADE IDENTIFY CALL")

                    })
                    .catch(err=>{
                        console.log('err getting accountProfile', err)
                    });

            })
            .catch(err=>{
                console.log('err getting userProfile', err)
            })
           
        })
        .catch(err => {
            console.log('could not sign in:', err);
            setAlertMessage(err.message);
        });

        

    }
    const signup = (email, password) => {
        auth.createUserWithEmailAndPassword(email, password).then(res=>{
            createUserProfile(email);
          }
        ).catch(err => {
            console.log('could not create user:', err);
        });
    }
    
    
    
    const onSubmit = data => {
        // console.log(data);
        if(path==="/signin"){
            console.log('signin', data);
            signin(data.email, data.password);
        } else if(path==="/signup"){
            console.log('signup', data);
            signup(data.email, data.password);
        }
    }

    return (
        <div className={className}>
            { user ? <Redirect to="/meeting" /> : null}

            <form onSubmit={handleSubmit(onSubmit)}>
                <H1>Sign {path==="/signup" ? 'Up': 'In'}</H1>

                { alertMessage && <Alert>{alertMessage}</Alert>}
            
                <Input name="email" label="Email Address" placeholder="Email Address" inputRef={register} />
                <Input name="password" type="password" label="Password" placeholder="Password" inputRef={register} />
                {path==="/signup" ?(
                    
                    <>
                    <Button id="signup" type="submit">Sign Up</Button>
                    <p>Already have an account? <Link to="/signin">Sign in.</Link></p>
                    </>
                ):(
                    <>
                    <Button id="signin" type="submit">Sign In</Button>
                    <p>New to Face2Face? <Link to="/signup">Sign Up Free</Link></p>
                    </>
                )}
            </form>
        </div>
    )
}
const AuthContent = styled(AuthContent_)`
    
`;

export default AuthContent