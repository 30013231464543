// import React from 'react';
import React, { Component } from "react";
import './App.scss';
import GlobalStyle from './style'
import Header from './ui/Header/Header';
import Footer from './ui/Footer';
import TwoColsLayout from './ui/Layouts/TwoColsLayout'
import AuthLayout from './ui/Layouts/AuthLayout'
import { Route, Switch } from 'react-router-dom';




class App extends Component {
  componentDidMount() {
  }

  render() {

    return (
      <>
        <GlobalStyle/>
        <div className="App">
          <Header/>
          <Switch>
            <Route path={["/signin", "/signup"]} component={AuthLayout} />
            <Route component={TwoColsLayout} />
          </Switch>
          {/* <Footer/> */}
        </div>
      </>
    );
  }
}

export default App;
