import styled from "styled-components";
import React, { useContext, useState, useEffect } from "react";
import Avatar from "./Avatar";
import { AuthContext } from "../../Auth/Auth";
import { Link } from "react-router-dom";
import { SettingsContext } from "../../state/settings";
import useCancelableTimeout from "use-cancelable-timeout";
import Caret from "../Elements/Caret";


const MeetingsMenu_ = ({className, onHover, onLeave}) => {
    const [settings, updateSettings] = useContext(SettingsContext)

    const onClose = e => {
        updateSettings({ activePopup: null });
    }

    const onMeetingMenuClick = e=>{
        settings.activePopup === 'meetingsDropdown' ? onClose() : onHover();
    }
    
    return (
        <div className={className} onPointerEnter={onHover} onPointerLeave={onLeave}>
            <div className="MenuItem" onClick={onMeetingMenuClick}>With Video Off</div>
            <div className="MenuItem" onClick={onMeetingMenuClick}>With Video On</div>
            <div className="MenuItem" onClick={onMeetingMenuClick}>Screen Share Only</div>
        </div>
    )
}

const MeetingsMenu = styled(MeetingsMenu_)`
    position: absolute;
    min-width: 100px;
    min-height: 20px;
    right: 26px;
    top: 24px;
    background: #fff;
    border: 1px solid #d3d3d3;
    box-sizing: border-box;
    border-radius: 8px;
    padding-top: 8px;
    padding-bottom: 8px;
    box-shadow: 0 0 15px rgba(0,0,0,0.15);
    line-height: 24px;
    white-space: nowrap;
    p {
        margin: 0;
    }
    .MenuItem {
        display: block;
        padding: 8px 16px!important;
        font-size: 14px;
        font-weight: normal;
        color: #232333;
        cursor: pointer;
        user-select: none;
        &:hover {
            text-decoration: none;
            background-color: #2d8cff;
            color: #fff;
        }
    }
    .divider {
        border-bottom: 1px solid #ededf3;
        padding-top: 8px;
        margin-bottom: 7px;
    }
`;


const LiWithDropdown_ = ({className, children}) => {
    const [settings, updateSettings] = useContext(SettingsContext)
    
    const [onPointerLeave, cancelLeave] = useCancelableTimeout(()=>{
        updateSettings({ activePopup: null });
    }, 1000);

    useEffect(()=>{cancelLeave()},[settings.activePopup]);

    const onHover = e=>{
        cancelLeave();
        updateSettings({ activePopup: 'meetingsDropdown' })
    }    

    return (
        <li className={className}>
            <span onPointerEnter={onHover} onPointerLeave={onPointerLeave}>{children} <Caret/></span>
            { settings.activePopup==='meetingsDropdown' && <MeetingsMenu onHover={onHover} onLeave={onPointerLeave} /> }
        </li>
    )
}

const LiWithDropdown = styled(LiWithDropdown_)`
    position: relative;

`;




const SignUpButton = styled(Link)`
    display: block;
    background-color: #f26d21;
    border: 1px solid #f26d21;
    
    margin-top: -2px;
    
    padding: 0 16px;
    min-width: 112px;
    height: 32px;
    line-height: 32px;
    border-radius: 8px;
    color: #fff;
    font-size: 14px;
    font-weight: 600;
    
    margin-right: 10px;
    margin-left: 15px;
    
    &&& {
        color: #fff;
    }
    &:hover {
        background-color: #da621e;
        text-decoration: none;
    }
`;

const LeftBar_ = ({className}) => {
    return (
        <ul className={className}>
            {/* <li id="web-solutions">SOLUTIONS</li>
            <li id="web-plans-pricing">PLANS & PRICING</li>
            <li id="web-contact-sales">CONTACT SALES</li> */}
        </ul>
    )
}
const LeftBar = styled(LeftBar_)`
    flex-grow: 1;
    justify-content: flex-start;
`

const RightBar_ = ({className}) => {
    const user = useContext(AuthContext);
    let showSignIn = true;
    if (user !== null && user != 0 ) {
        showSignIn = false;
    }
    
    return (
        <ul className={className}>

            {!showSignIn &&
            <>
                <li><Link id="header-schedule-meeting" to="/meeting/schedule">SCHEDULE A MEETING</Link></li>
                <li><Link id="header-join-meeting" to="/meeting/join">JOIN A MEETING</Link></li>
            </>
            }
            {/* <LiWithDropdown>HOST A MEETING</LiWithDropdown> */}
            {showSignIn &&
            <>
                <li><Link id="menu-signin" to="/signin">SIGN IN</Link></li>
                <li><SignUpButton id="menu-signup" to="/signup">SIGN UP, IT'S FREE</SignUpButton></li>
            </>
            }
        </ul>
    )
}
const RightBar = styled(RightBar_)`

    && {
        /* color: #0c63ce; // ORIG BLUE */
        color: var(--sidebar-button-color);
        a {
            /* color: #0c63ce; ORIG BLUE */
            color: var(--sidebar-button-color);
        }
    }

`



const Logo = styled.div`
    padding: 0 0 0 24px;
    background-image: url(/logo.png);
    width: 230px;
    height: 75px;
    background-repeat: no-repeat;
    background-size: cover;
    background-origin: content-box;

    a, a:hover {
        text-decoration: none;
        color: var(--logo-text-color);
    }
`


const Container = styled.div`
    min-height: 64px;
    background-color: #fffeff;
    display: flex;
    align-items: center;
    justify-content: space-between;

    ul {
        color: #777;
        margin: 0;
        padding: 0;
        display: flex;
        align-items: center;
        li {
            display: block;
            text-decoration: none;
            padding: 0 5px 0 15px;
            cursor: pointer;
            &:hover {
                /* color: #0e71eb; ORIG BLUE */ 
                color: var(--sidebar-button-color);
            }
            &:last-child {
                padding-right: 25px;
            }


        }
    }
`;


export default ({className}) => {
    
    return (
        <Container className={className}>
            <Link  to="/">
                <Logo className="header-logo"></Logo>
            </Link>
            <LeftBar/>
            <RightBar/>
            <Avatar />
        </Container>
    )
}
